@charset "utf-8";
/* CSS Document */

body {
  height: 100%;
  font-family: Lato, sans-serif;
}
.body-agency {
	background-color: #000000;
	font-family: 'Jura', sans-serif;
	line-height: 1.8;
  color: #12FF00;
  h2 {
    text-transform: uppercase;
    font-weight: 600;
  }
  h4 {
    line-height: 1.375em;
    font-weight: 400;
  }
}
.body-snc {
  color: #818181;
  h1, h2 {
    font-family: Bangers, cursive;
    color: #303030;
  }
  h4 {
    font-family: Bangers, cursive;
    color: #303030;
  /*	font-weight: 400;
    font-size: 19px;*/
  }
}
.body-gf {
  color: #333333;
  background-color: #FFCC66 !important;
  h1 {
    font-family: 'Frijole', cursive;
    color: #303030;
  }
  h2 {
    font-family: Bangers, cursive;
    color: #474547;
  }
  h3 {
    font-family: Bangers, cursive;
    color: #565555;
  }
  h4 {
    font-family: Bangers, cursive;
    color: #606060;
  }
}

header {
	margin: 0px;
	padding: 50px 0px 0px 0px;
}

.bg {
	background-image: url(https://www.supernewcity.com/assets/images/footer-night-2648x1080.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
}
.bg-blue {
	background-color: rgba(0,0,255,0.10);
	border-color: rgba(0,0,255,1.00);
}
.bg-grey {
	background-color: #e6e6e6;
}
.bg-purple {
	background-color: #6B0AA3;
}
.bg-red {
  background-color: #cc0000;
}
.bg-green {
	background-color: rgba(0,255,0,0.10);
	border-color: rgba(0,255,0,1.00);
}
.bg-yellow-brown {
	background-color: #c8a83a;
}
.bg-beige {
	background-color: #FFCC66;
}
.bg-tan {
	background-color: #FBEEAD;
	border-color: #57331D;
}
.bg-offwhite {
    background-color: #FFF1DB;
    border-color: #523825;
}
.bg-yellow {
	background-color: rgba(255,255,0,0.1);
	border-color: rgba(255,255,0,1.00);
}

.text-neutral-500 {
	color: #999999;
}
.text-neutral-600 {
	color: #565555;
}
.navbar {
	margin-bottom: 0;
/*	z-index: 9999;*/
	border: 0;
	font-family: 'Bangers', cursive;
	font-size: 1.5em !important;
	line-height: 1.42857143 !important;
	letter-spacing: 4px;
	border-radius: 0;
}

.navbar li a, .button, .navbar .navbar-brand {
	color: #fff !important;
}

a.nav-link {
	color:#FFFFFF;
}
a.dropdown-item{
	color: #FFFFFF;
}
/* need to fix this dupe */
.navbar-nav li a:hover, .navbar-nav li.active a {
	color: #f4511e !important;
	background-color: #fff !important;
}
.navbar-nav li a:hover, .navbar-nav li.active a {
	color: #CC0000 !important;
	background-color: #fff !important;
}

@media screen and (max-width: 768px) {
  .col-sm-4 {
    text-align: center;
    margin: 25px 0;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  .bg {
    background-attachment: scroll;
  }
}